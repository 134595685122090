import React from 'react'; 
// import Avatar from './avatar';
// import pfp from '../images/pfp.jpg';


const Hero = () => {
  return (
    <div id='hero' className='container-fluid hero'>
      <h2>Hi<span className='shaker'>!</span> I'm</h2>
      <h1>Devin Menzies</h1>
      <h2>Web Developer</h2>
          {/* <Avatar img={pfp}/>  */}
    </div>
  )
}

export default Hero; 